export const IMAGE_TYPES = ['png', 'jpg', 'jpeg', 'gif', 'tif', 'tiff'];

export const STATUS = {
  All: 0,
  Active: 1,
  Inactive: 2,
};

export const TRANSACTION_STATUS = [
  {
    label: 'Posted Successfully',
    value: 'Posted Successfully',
    status: 1,
  },
  {
    label: 'Posting Failed',
    value: 'Posted Failed',
    status: 3,
  },
  {
    label: 'Pending Review',
    value: 'Pending Review',
    status: 2,
  },
  {
    label: 'Pending Approval',
    value: 'Pending Approval',
    status: 4,
  },
  {
    label: 'Approved',
    value: 'Approved',
    status: 5,
  },
  {
    label: 'Rejected',
    value: 'Rejected',
    status: 6,
  },
];

export const USER_ROLES = [
  {
    label: 'Admin',
    value: 'Admin',
  },
  {
    label: 'Normal',
    value: 'Normal',
  },
];

export const USER_STATUS = [
  {
    label: 'Active',
    value: 0,
  },
  {
    label: 'Inactive',
    value: 1,
  },
];

export const USER_STATUS_FILTER = [
  {
    label: 'All',
    value: 2,
  },
  {
    label: 'Active Only',
    value: 0,
  },
  {
    label: 'Inactive Only',
    value: 1,
  },
];

export const TRANSACTION_TYPES = [
  {
    label: 'Retail',
    value: 'Retail',
  },
  {
    label: 'Terminal',
    value: 'Terminal',
  },
  {
    label: 'Manual Posting',
    value: 'Manual Posting',
  },
  {
    label: 'Adjustment',
    value: 'Adjustment',
  },
  {
    label: 'Cancellation',
    value: 'Cancellation',
  },
];

export const STORE_SEGMENT_TYPES = [
  {
    label: 'Static',
    value: 0,
  },
  {
    label: 'Filter Base',
    value: 1,
  },
];

export const SEGMENT_STORE_CONDITIONS = [
  {
    label: 'ADD',
    value: 'ADD',
  },
  {
    label: 'OR',
    value: 'OR',
  },
];

export const SEGMENT_STORE_ACTION = [
  {
    label: 'Store Level',
    value: 'Store Level',
  },
  {
    label: 'Category',
    value: 'Category',
  },
  {
    label: 'Store Type',
    value: 'Store Type',
  },
  {
    label: 'SR Effective Period',
    value: 'SR Effective Period',
  },
];

export const SEGMENT_STORE_OPERATION = [
  {
    label: 'Equal',
    value: 'Equal',
  },
  {
    label: 'Exclude',
    value: 'Exclude',
  },
];

export const ROLE_TYPES = [
  {
    label: 'Retail',
    value: 2,
  },
  {
    label: 'TMs',
    value: 3,
  },
];

export const CAMPAIGN_TYPES = [
  {
    label: 'Admin Fee',
    value: 1,
  },
  {
    label: 'Marketing Message',
    value: 2,
  },
  {
    label: 'Earning Rate',
    value: 3,
  },
];

export const CAMPAIGN_STATUS = [
  {
    label: 'Draft',
    value: 0,
  },
  {
    label: 'Published',
    value: 1,
  },
  {
    label: 'Live',
    value: 1,
  },
  {
    label: 'Paused',
    value: 1,
  },
  {
    label: 'Expired',
    value: 1,
  },
];

export const PATRON_SEGMENT_DATA = [
  {
    label: 'Membership',
    value: 0,
  },
  {
    label: 'Patron Segment',
    value: 1,
  },
  {
    label: 'Accumulated Spending',
    value: 2,
  },
];

export const PATRON_SEGMENT_CONJUSTION = [
  {
    label: 'AND',
    value: 0,
  },
  {
    label: 'OR',
    value: 1,
  },
];

export const PAYMENT_TYPES = {
  rewardDollars: 0,
  voucher: 1,
  cash: 2,
  evoucher: 3,
};

export const CAMPAIGN_TYPES_FILTER = [
  {
    label: 'All',
    value: 0,
  },
  {
    label: 'Admin Fee',
    value: 1,
  },
  {
    label: 'Marketing Message',
    value: 2,
  },
  {
    label: 'Earning Rate',
    value: 3,
  },
];

export const LOOKUP_STATUS = [
  {
    label: 'Active',
    value: true,
  },
  {
    label: 'Inactive',
    value: false,
  },
];

export const LOOKUP_CARDTIER_STATUS = [
  {
    label: 'Active',
    value: 1,
  },
  {
    label: 'Inactive',
    value: 0,
  },
];

export const STORE_STATUS_FILTER = [
  {
    label: 'Show All',
    value: null,
  },
  {
    label: 'Active Only',
    value: true,
  },
  {
    label: 'Inactive Only',
    value: false,
  },
];

export const WATCH_LIST_RULES_STATUS = [
  {
    label: 'Active',
    value: 1,
  },
  {
    label: 'Inactive',
    value: 2,
  },
];

export const WATCH_LIST_TRANSACTIONS_STATUS = [
  {
    label: 'All',
    value: 0,
  },
  {
    label: 'Watchlist',
    value: 1,
  },
  {
    label: 'Released',
    value: 2,
  },
];

export const CONTACT_STATUS = [
  {
    label: 'All (Default)',
    value: 0,
  },
  {
    label: 'Active',
    value: 1,
  },
  {
    label: 'Inactive',
    value: 2,
  },
];

export const TERMINAL_FEATURES = [
  {
    label: 'SL Program',
    value: 1,
  },
  {
    label: 'eVoucher Program',
    value: 2,
  },
];
