import { useState, useEffect } from 'react';
import { PERMISSION, NO_CHECK_ACCESS_ROUTES } from 'utils/constant';
import { useIsRetailer } from './useAuth';
import { useStore } from './useStore';

const getAccessRoutes = (routes, permissions) =>
  routes?.filter(
    (route) =>
      NO_CHECK_ACCESS_ROUTES.includes(route?.path) ||
      permissions
        ?.filter(
          (permission) =>
            PERMISSION[route?.key]?.functionName === permission?.functionName ||
            (!!route?.routes?.length &&
              route?.routes?.some(
                (nestedRoute) =>
                  PERMISSION[nestedRoute?.key]?.functionName ===
                  permission?.functionName,
              )),
        )
        ?.some((permission) => permission?.canAccess),
  );

const useAccessRoutes = (routes) => {
  const [{ user, storeValue, configs }] = useStore();
  const isRetailer = useIsRetailer();

  const currentRetailerStore = configs?.stores_full_info?.find(
    (store) => store?.id === storeValue,
  );
  const hasEvoucherProgram = currentRetailerStore?.has_qr_integration;

  const [accessRoutes, setAccessRoutes] = useState([]);
  useEffect(() => {
    if (user?.permissions) {
      const newAccessRoutes = getAccessRoutes(routes, user?.permissions)?.map(
        (nestedRoute) => ({
          ...nestedRoute,
          routes: getAccessRoutes(nestedRoute?.routes, user?.permissions),
        }),
      );
      setAccessRoutes(newAccessRoutes);
    }
  }, [user?.permissions, isRetailer]);

  return [
    accessRoutes?.filter(
      (route) =>
        !(
          isRetailer &&
          !hasEvoucherProgram &&
          route?.key === 'EVOUCHER_USAGE_REPORT'
        ),
    ),
  ];
};

export default useAccessRoutes;
